import React from "react";
import ImageGallery from "react-image-gallery";

const Banners = ({ lists }) => {
  const galleryRef = React.createRef();

  const items = lists.map((item, i) => ({
    original: item.url,
    originalClass: "banner",
    width: 1200,
    height: 250
  }));

  const handleClick = (e) => {
    const idx = galleryRef.current.getCurrentIndex();
    const url = `/lists/${lists[idx].id}/auctions`;
    location.href = url;
  };


  return (
    <ImageGallery
      additionalClass="banners-gallery"
      ref={galleryRef}
      items={items}
      onClick={handleClick}
      showThumbnails={false}
      showBullets={true}
      showNav={true}
      autoPlay={true}
      slideInterval={5000}
      showPlayButton={false}
      showFullscreenButton={false}
    />
  );
};

export default Banners;
